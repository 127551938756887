import React, { useEffect, useState } from 'react';
import SectionContainer from '../../../molecules/SectionContainer/SectionContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from "moment-timezone";
import classes from "./FlashSaleModule.module.css";
import { getAuction } from '../../../../../redux/eventReducer';

function FlashSaleModule() {
    const dispatch = useDispatch();
    const { auctions, isLoading } = useSelector(state => state.auction);

    useEffect(() => {
        if (auctions?.total_page === 0) {
            dispatch(getAuction({ page: 1, limit: 20, cause_category_id: null }));
        }
    }, [dispatch, auctions?.total_page]);

    console.log("Auction Data:", auctions); 

    return (
      <SectionContainer>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {auctions?.data?.length > 0 &&
            auctions.data.some((auction) => auction.ui === "flash_sale") ? (
              <>
                <h3 className="newTitleClass">FLASH SALES</h3>
                <div className={classes.auctionGrid}>
                  {auctions?.data?.map(
                    (auction, i) =>
                      auction.ui === "flash_sale" && (
                        <HomeEventCard
                          key={i}
                          auction={auction}
                          eventStatus="live"
                        />
                      )
                  )}
                </div>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </SectionContainer>
    );
}

export default FlashSaleModule;

const HomeEventCard = ({ auction, eventStatus = 'live' }) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        const queryParams = (auction.has_buy_now === "0" || auction.has_auction === "0") ? "?item-type=event" : "";
        navigate(`/${auction.is_store === 1 ? 'store' : 'event'}/${auction.custom_url ? auction.custom_url : auction.id}${queryParams}`, { state: { auction: auction, image: auction?.image2, from: 'event' } });
    };

    const calculateDaysLeft = (endDate) => {
        const now = new Date();
        const end = new Date(endDate);
        const timeDiff = end - now;
        return Math.max(0, Math.ceil(timeDiff / (1000 * 60 * 60 * 24)));
    };

    const daysLeft = calculateDaysLeft(auction.end_date);

    return (
        <div className={classes.card} onClick={handleNavigate}>
            <div className={classes.imageContainer}>
                <img src={`${auction.image2}_small`} alt={auction.title} />
                <div className={classes.text_field}>
                    <p className={classes.daysLeft}>ENDS IN {daysLeft} DAY{daysLeft !== 1 ? 'S' : ''}</p>
                    <p className={classes.title}>{auction.title}</p>
                </div>
            </div>
        </div>
    );
};
