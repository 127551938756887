
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import orderServices from "../../../../services/order-services";
import { createPayment, fetchSavedCard } from "../../../../services/payment-services";
import userServices from "../../../../services/user-services";
import AuthContext from "../../../../store/auth-context";
import { getCartItems } from "../../../../redux/cartReducer"; 

import EditSVG from "../../../../images/edit.svg"
import "./cart.css";
import logger from "../../../../utils/logger";
import { Purchase } from "../../../../utils/meta-pixel-tracking";
import DialogMui from "../../molecules/Dialog/Dialog";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import constants from "../../../../constants/en";

import { resetCart } from "../../../../redux/cartReducer";
import { mobileStyle } from "../../Home";
import HeaderV2 from "../../molecules/Header/HeaderV2";
import { useMediaQuery } from "react-responsive";
import NewButton from "../../atoms/NewButton/NewButton";
import NeedHelp from "../../atoms/NeedHelp/NeedHelp";
import NewCardLayout from "../../atoms/NewCardLayout/NewCardLayout";
import LoadingScreen from "../../LoadingScreen/Loading";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MobileSubFooter from "../../atoms/MobileSubFooter/MobileSubFooter";
import NewFooter from "../../molecules/NewFooter/NewFooter";
import HeaderV3 from "../../molecules/Header/HeaderV3/HeaderV3";
import { getDiscount, getDisplayPrice, getDisplayVariantPrice, getVariantDiscount } from "../../../../utils/displayPrice";


const totalprice = (cartItems) => {
  let sumWithoutDiscount = 0;
  let sum = 0;
  let advatixSum = 0;
  let selfSum = 0;
  let virtualSum = 0;
  let ediSum = 0;
  let discountPriceSum = 0;
  for (let i = 0; i < cartItems?.length; i++) {
    if (cartItems[i]?.auction_item?.store_item?.variant) {
      if(cartItems[i]?.auction_item?.store_item?.variant.quantity_in_stock >= cartItems[i].quantity){
        sum +=(Number(+cartItems[i]?.quantity)) * Number(getDisplayVariantPrice(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item));
        discountPriceSum +=(Number(+cartItems[i]?.quantity)) * Number(getVariantDiscount(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item));
        if(cartItems[i].auction_item.fulfilment.name === "advatix"){
          advatixSum +=(Number(+cartItems[i]?.quantity)) * Number(getDisplayVariantPrice(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item)) 
        }else if(cartItems[i].auction_item.fulfilment.name === "self"){
          selfSum +=(Number(+cartItems[i]?.quantity)) * Number(getDisplayVariantPrice(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item))
        }else if(cartItems[i].auction_item.fulfilment.name === "edi") {
          ediSum +=(Number(+cartItems[i]?.quantity)) * Number(getDisplayVariantPrice(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item))
        }else{
          virtualSum +=(Number(+cartItems[i]?.quantity)) * Number(getDisplayVariantPrice(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item))
        }
      }

    } else {
      if(cartItems[i]?.auction_item?.store_item?.quantity_in_stock >= cartItems[i].quantity){
        sum +=(Number(+cartItems[i]?.quantity)) * ( Number(getDisplayPrice(cartItems[i]?.auction_item)));
        discountPriceSum +=(Number(+cartItems[i]?.quantity)) * (Number(getDiscount(cartItems[i]?.auction_item)))
        if(cartItems[i].auction_item.fulfilment.name === "advatix"){
          advatixSum +=(Number(+cartItems[i]?.quantity)) * (Number(getDisplayPrice(cartItems[i]?.auction_item))) 
        }else if(cartItems[i].auction_item.fulfilment.name === "self"){
          selfSum +=(Number(+cartItems[i]?.quantity)) * (Number(getDisplayPrice(cartItems[i]?.auction_item)))
        }else if(cartItems[i].auction_item.fulfilment.name === "edi") {
          ediSum +=(Number(+cartItems[i]?.quantity)) * (Number(getDisplayPrice(cartItems[i]?.auction_item)))
        }else{
          virtualSum +=(Number(+cartItems[i]?.quantity)) * (Number(getDisplayPrice(cartItems[i]?.auction_item)))
        }
      }     
    }
  }
  sumWithoutDiscount = sum;
  return [sum - discountPriceSum,advatixSum,selfSum,virtualSum, ediSum, discountPriceSum,sumWithoutDiscount];
};

const totalTaxablePrice = (cartItems) => {
  let sum = 0;
  for(const item of cartItems) {
    if(+item?.auction_item?.store_item?.charge_tax !== 0) {
      if (item?.auction_item?.store_item?.variant) {
        if(item?.auction_item?.store_item?.variant.quantity_in_stock >= item.quantity){
          sum +=(+item?.quantity) * ((Number(getDisplayVariantPrice(item?.auction_item?.store_item?.variant, item?.auction_item)))-(Number(getVariantDiscount(item?.auction_item?.store_item?.variant, item?.auction_item)))); 
        }
  
      } else {
        if(item?.auction_item?.store_item?.quantity_in_stock >= item.quantity){
          sum +=(+item?.quantity) * ((getDisplayPrice(item?.auction_item))-(getDiscount(item?.auction_item)));
        }     
      }
    }
  }
  return sum
}

const CartCheckout = () => {
  const authCtx = useContext(AuthContext);
  // const [cartItems, setCartItems] = useState([]);
  const [price, setPrice] = useState(0);
  const [shippingRate, setShippingRate] = useState(0);
  const [tax, setTax] = useState(0)
  const [shipingData, setShippingData] = useState(null)
  const [cardDetails, setCardDetails] = useState(null);
  const [isOrderProcessing, setIsOrderProcessing] = useState(false);

  const [advatixItems,setAdvatixItems]=useState([])
  const [ediOrderItems, setEdiOrderItems] = useState([]);
  const [ediOrderItemsByDonor, setEdiOrderItemsByDonor] = useState([]);
  const [selfOrderItems,setselfOrderItems]=useState([])
  const [virtualItems,setvirtualItems]=useState([])

  const [modal,setModal]=useState({isOpen:false,message:''});
  const [modal2,setModal2]=useState({isOpen:false,message:''});
  const [noOfEvent,setNumberOfevent]=useState(1);
  const [uniqueEventId, setUniqueEventId]= useState(null);

  const [advatixItemPrice,setadvatixItemPrice] = useState(0);
  const [selfOrderItemPrice,setSelfOrderItemPrice] = useState(0);
  const [virtualOrderItemPrice,setvirtualOrderItemPrice] = useState(0);
  const [ediOrderItemPrice, setEdiOrderItemPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [totalPriceWithoutDiscount, setTotalPriceWithoutDiscount] = useState(0);

  const [advatixShippingRate,setadvatixShippingPrice] = useState(0);
  const [selfShippingRate,setselfShippingPrice] = useState(0);
  const [ediShippingRate, setEdiShippingPrice] = useState(0);

  const [advatixSaleTax,setadvatixSaleTax] = useState(0);
  const [selfItemSaleTax,setSelfItemSaleTax] = useState(0);
  const [virtualSaleTax,setVirtualSaleTax]= useState(0);
  const [ediSaleTax, setEdiSaleTax] = useState(0);

  const dispatch = useDispatch()
  const {data:cartItems}=useSelector(state=>state.cartDetails)
  const primaryBackgroundColor = "var(--pure-white)"
  const primaryColor = mobileStyle.color[1]
  const redColor = mobileStyle.backgroundColor[0]
  const [tooltip1,setTooltip1] = useState(false);
  const [tooltip2,setTooltip2] = useState(false);

  const [showDiscountPercentage,setShowDiscountPercentage] = useState(true);

  let currentDiscountFromAuction =[];

  if (cartItems.length > 0) {
    cartItems.forEach((item) => {

      const isDiscount = item.auction_item?.auction_auction_item_xrefs?.[0]?.auction?.discount_percentage ? item.auction_item?.auction_auction_item_xrefs?.[0]?.auction?.discount_percentage : item.auction_item?.auction?.discount_percentage;
      if(isDiscount){
        currentDiscountFromAuction.push(isDiscount);
      }
    });

    // const uniqueDiscounts = [...new Set(currentDiscountFromAuction)];

    // // If there's more than one unique discount, set `setShowDiscountPercentage(false)`
    // if (uniqueDiscounts.length > 1) {

    //   setShowDiscountPercentage(false);
    // }
  }

  useEffect(() => {
    const uniqueDiscounts = [...new Set(currentDiscountFromAuction)];
  
    if (uniqueDiscounts.length > 1 && showDiscountPercentage !== false) {
      setShowDiscountPercentage(false);
    }else if (uniqueDiscounts.length === 1){
      setShowDiscountPercentage(true);
    }
  }, [cartItems.length,currentDiscountFromAuction]);

  const isMobile = useMediaQuery({
    maxWidth: 768
  })

  const navigate = useNavigate();
  useEffect(() => {
    if(!authCtx.isLoggedIn){
      return
    }
    (async () => {     
      const { data } = await userServices.getUserShippingAddress(authCtx?.userId)
      data?setShippingData(data):(authCtx?.cardId && setModal2({isOpen:true,message:"Please add shipping details (even if you're just making a pledge, it's required by our system to process orders)"}));
    })()

  !cartItems.length && navigate(-1)
  }, [authCtx.cardId]);

  

  const getShippingRate = async (cartItems) => {
    let sum = 0;
    let productsTotalWeight = 0;
    for (let i = 0; i < cartItems.length; i++) {
      if (cartItems[i].auction_item.is_virtual === 0 && cartItems[i].auction_item.fulfilment.pickup_type === "ship") {
        if(cartItems[i].auction_item.store_item.variant){
          if(cartItems[i].auction_item.store_item.variant.quantity_in_stock >= cartItems[i].quantity){
            /// this is commenting for temporary purpose for showing same shipping rate for all orders that is $9.99
            // const shippingRate = await orderServices.getShippingRate(
            //   cartItems[i].auction_item_id
            // );
            // sum+=( cartItems[i].quantity * (+shippingRate?.data?.shipping_rate));
            // sum = 9.99

            // new logic for shiping_rate
            productsTotalWeight += (+cartItems[i].auction_item?.weight) * (+cartItems[i].quantity);
          }
        }else{
          if(cartItems[i].auction_item.store_item.quantity_in_stock >= cartItems[i].quantity){
            /// this is commenting for temporary purpose for showing same shipping rate for all orders that is $9.99
            // const shippingRate = await orderServices.getShippingRate(
            //   cartItems[i].auction_item_id
            // );
            // sum+=(cartItems[i].quantity * (+shippingRate?.data?.shipping_rate));
            // sum = 9.99

            // new logic for shiping_rate
            productsTotalWeight += (+cartItems[i].auction_item?.weight) * (+cartItems[i].quantity);
          }
        }        
      }
    }
    // return sum;

    // new logic
    if(productsTotalWeight > 0) {
      const shippingRate = await orderServices.getShippingRate(
        productsTotalWeight
      );
      return +shippingRate?.data?.shipping_rate
    }
    else {
      return 0
    }
  };

  const checkEmptyShipping = (error) => {
    if(error?.response?.data?.noShipping == true) {
      // if there is no shipping then we don't need to go back to the cart page. We need to move to shipping details page.
      return true
    }
    else {
      return false
    }
  }

  const getSalesTax = async (amount) => {
    try {
      const tax = await orderServices.getSalesTax(amount)
      if(tax.state!=="CA"){
        tax.amount = 0
      }
      setTax(tax)
      return tax
    }catch(error) {
      logger('./pages/components/organisms/cart/cart.jsx', 'getSalesTax()', {error:error});
      if(!checkEmptyShipping(error)) {
        // If user has shipping and error is some thing else then we will go back to cart page.
        alert(error.response.data.message || constants.APP_COPY_ORDER_SALES_TAX_ERROR)
        return false
      }
      else {
        return true
      }
    }
  };
  const getAdvatixSaleTax = async (amount) => {
    try {
      const tax = await orderServices.getSalesTax(amount)
      if(tax.state!=="CA"){
        tax.amount = 0
      }
      setadvatixSaleTax(tax)
      return tax
    }catch(error) {
      logger('./pages/components/organisms/cart/cart.jsx', 'getAdvatixSaleTax()', {error:error});
      if(!checkEmptyShipping(error)) {
       alert(error.response.data.message || constants.APP_COPY_ORDER_SALES_TAX_ERROR)
       return false
      }
      else {
        return true
      }
    }
  };
  const getSelfOrderSaleTax = async (amount) => {
    try {
      const tax = await orderServices.getSalesTax(amount)
      if(tax.state!=="CA"){
        tax.amount = 0
      }
      setSelfItemSaleTax(tax)
      return tax
    }catch(error) {
      logger('./pages/components/organisms/cart/cart.jsx', 'getSelfOrderSaleTax()', {error:error});
      if(!checkEmptyShipping(error)){
        alert(error.response.data.message || constants.APP_COPY_ORDER_SALES_TAX_ERROR)
        return false
      }
      else {
        return true
      }
    }
  };
  const getVirtualSaleTax = async (amount) => {
    try {
      const tax = await orderServices.getSalesTax(amount)
      if(tax.state!=="CA"){
        tax.amount = 0
      }
      setVirtualSaleTax(tax)
      return tax
    }catch(error) {
      logger('./pages/components/organisms/cart/cart.jsx', 'getVirtualSaleTax()', {error:error});
      if(!checkEmptyShipping(error)) {
        alert(error.response.data.message || constants.APP_COPY_ORDER_SALES_TAX_ERROR)
        return false
      }
      else {
        return true
      }
    }
  };
  const getEdiSaleTax = async (amount) => {
    try {
      const tax = await orderServices.getSalesTax(amount)
      setEdiSaleTax(tax)
      return tax
    }catch(error) {
      logger('./pages/components/organisms/cart/cart.jsx', 'getEdiSaleTax()', {error:error});
      if(!checkEmptyShipping(error)) {
        alert(error.response.data.message || constants.APP_COPY_ORDER_SALES_TAX_ERROR)
        return false
      }
      else {
        return true
      }
    }
  }

  const separateItems = (items) =>{
    let advtItmes=[];
    let selfOrdItems=[];
    let virItems=[];
    let ediItems=[];
    let ediItemsByDonor = {}
    for(let i = 0; i < items.length ;i ++){
      if(items[i].auction_item.store_item.variant){
        if(items[i]?.auction_item?.store_item?.variant?.quantity_in_stock >= items[i].quantity){
          if(items[i]?.auction_item?.fulfilment?.name === "advatix"){
            advtItmes.push(items[i])
          }else if(items[i]?.auction_item?.fulfilment?.name === "self"){
            selfOrdItems.push(items[i])
          }else if(items[i]?.auction_item?.fulfilment?.name === "virtual" || items[i]?.auction_item?.is_virtual === 1){
            virItems.push(items[i])
          }
          else if(items[i]?.auction_item?.fulfilment?.name === "edi") {
            ediItems.push(items[i])
            const item = items[i];
            const donor_id = item?.auction_item?.donor_id;
            if (!ediItemsByDonor[donor_id]) {
                ediItemsByDonor[donor_id] = [];
            }
            ediItemsByDonor[donor_id].push(item);
          }
        }
      }else{
        if(items[i].auction_item.store_item.quantity_in_stock >= items[i].quantity){
          if(items[i]?.auction_item?.fulfilment?.name === "advatix"){
            advtItmes.push(items[i])
          }else if(items[i]?.auction_item?.fulfilment?.name === "self"){
            selfOrdItems.push(items[i])
          }else if(items[i]?.auction_item?.fulfilment?.name === "virtual" || items[i]?.auction_item?.is_virtual === 1){
            virItems.push(items[i])
          }
          else if(items[i]?.auction_item?.fulfilment?.name === "edi") {
            ediItems.push(items[i])
            const item = items[i];
            const donor_id = item?.auction_item?.donor_id;
            if (!ediItemsByDonor[donor_id]) {
                ediItemsByDonor[donor_id] = [];
            }
            ediItemsByDonor[donor_id].push(item);
          }
        }
      }
    }
    return [advtItmes , selfOrdItems , virItems, ediItems, Object.values(ediItemsByDonor)]/// the last element is the array which includes arrays with objects of items according to donor_id eg: [[{},{}], [{},{}], [{},{}]]
  }
  useEffect(()=>{
       dispatch(getCartItems({loggedIn: authCtx.isLoggedIn}))
  },[])

  useEffect(() => {
    if(!authCtx.isLoggedIn){
      return
    }
    (async () => {
    const getEventId = (items) =>{
      let eventIds = [];
      for(let i = 0; i < items.length ;i ++){
        if(items[i].auction_item.store_item.variant){
          if(items[i]?.auction_item?.store_item?.variant?.quantity_in_stock >= items[i].quantity){
            // eventIds.push(items[i]?.auction_item?.auction?.id)
            eventIds.push(items[i]?.auction_id)
          }
        }else{
          if(items[i].auction_item.store_item.quantity_in_stock >= items[i].quantity){
            // eventIds.push(items[i]?.auction_item?.auction?.id)
            eventIds.push(items[i]?.auction_id)
          }
        }
      }
      return eventIds
    }
    // const getShippingRate = async (cartItems) => {
    //   let sum = 0;
    //   let productsTotalWeight = 0;
    //   for (let i = 0; i < cartItems.length; i++) {
    //     if (cartItems[i].auction_item.is_virtual === 0 && cartItems[i].auction_item.fulfilment.pickup_type === "ship") {
    //       if(cartItems[i].auction_item.store_item.variant){
    //         if(cartItems[i].auction_item.store_item.variant.quantity_in_stock >= cartItems[i].quantity){
    //           /// this is commenting for temporary purpose for showing same shipping rate for all orders that is $9.99
    //           // const shippingRate = await orderServices.getShippingRate(
    //           //   cartItems[i].auction_item_id
    //           // );
    //           // sum+=( cartItems[i].quantity * (+shippingRate?.data?.shipping_rate));
    //           // sum = 9.99

    //           // new logic for shiping_rate
    //           productsTotalWeight += (+cartItems[i].auction_item?.weight) * (+cartItems[i].quantity);
    //         }
    //       }else{
    //         if(cartItems[i].auction_item.store_item.quantity_in_stock >= cartItems[i].quantity){
    //           /// this is commenting for temporary purpose for showing same shipping rate for all orders that is $9.99
    //           // const shippingRate = await orderServices.getShippingRate(
    //           //   cartItems[i].auction_item_id
    //           // );
    //           // sum+=(cartItems[i].quantity * (+shippingRate?.data?.shipping_rate));
    //           // sum = 9.99

    //           // new logic for shiping_rate
    //           productsTotalWeight += (+cartItems[i].auction_item?.weight) * (+cartItems[i].quantity);
    //         }
    //       }        
    //     }
    //   }
    //   // return sum;

    //   // new logic
    //   if(productsTotalWeight > 0) {
    //     const shippingRate = await orderServices.getShippingRate(
    //       productsTotalWeight
    //     );
    //     return +shippingRate?.data?.shipping_rate
    //   }
    //   else {
    //     return 0
    //   }
    // };

    // const getSalesTax = async (amount) => {
    //   const tax = await orderServices.getSalesTax(amount)
    //   setTax(tax)
    // };
    // const getAdvatixSaleTax = async (amount) => {
    //   const tax = await orderServices.getSalesTax(amount)
    //   setadvatixSaleTax(tax)
    // };
    // const getSelfOrderSaleTax = async (amount) => {
    //   const tax = await orderServices.getSalesTax(amount)
    //   setSelfItemSaleTax(tax)
    // };
    // const getVirtualSaleTax = async (amount) => {
    //   const tax = await orderServices.getSalesTax(amount)
    //   setVirtualSaleTax(tax)
    // };
    // const getEdiSaleTax = async (amount) => {
    //   const tax = await orderServices.getSalesTax(amount)
    //   setEdiSaleTax(tax)
    // }

    const cardDetails = async () => {
      if(authCtx.cusId &&authCtx.cardId){
        const card = await fetchSavedCard(authCtx.cusId, authCtx.cardId)
        setCardDetails({
          name: card.name,
          last4: card.last4,
          brand: card.brand
        })
      } else {
        setModal({isOpen:true,message:constants.APP_COPY_CART_NO_CARD_DETAILS})
      }
    }

  
    // const separateItems = (items) =>{
    //   let advtItmes=[];
    //   let selfOrdItems=[];
    //   let virItems=[];
    //   let ediItems=[];
    //   let ediItemsByDonor = {}
    //   for(let i = 0; i < items.length ;i ++){
    //     if(items[i].auction_item.store_item.variant){
    //       if(items[i]?.auction_item?.store_item?.variant?.quantity_in_stock >= items[i].quantity){
    //         if(items[i]?.auction_item?.fulfilment?.name === "advatix"){
    //           advtItmes.push(items[i])
    //         }else if(items[i]?.auction_item?.fulfilment?.name === "self"){
    //           selfOrdItems.push(items[i])
    //         }else if(items[i]?.auction_item?.fulfilment?.name === "virtual" || items[i]?.auction_item?.is_virtual === 1){
    //           virItems.push(items[i])
    //         }
    //         else if(items[i]?.auction_item?.fulfilment?.name === "edi") {
    //           ediItems.push(items[i])
    //           const item = items[i];
    //           const donor_id = item?.auction_item?.donor_id;
    //           if (!ediItemsByDonor[donor_id]) {
    //               ediItemsByDonor[donor_id] = [];
    //           }
    //           ediItemsByDonor[donor_id].push(item);
    //         }
    //       }
    //     }else{
    //       if(items[i].auction_item.store_item.quantity_in_stock >= items[i].quantity){
    //         if(items[i]?.auction_item?.fulfilment?.name === "advatix"){
    //           advtItmes.push(items[i])
    //         }else if(items[i]?.auction_item?.fulfilment?.name === "self"){
    //           selfOrdItems.push(items[i])
    //         }else if(items[i]?.auction_item?.fulfilment?.name === "virtual" || items[i]?.auction_item?.is_virtual === 1){
    //           virItems.push(items[i])
    //         }
    //         else if(items[i]?.auction_item?.fulfilment?.name === "edi") {
    //           ediItems.push(items[i])
    //           const item = items[i];
    //           const donor_id = item?.auction_item?.donor_id;
    //           if (!ediItemsByDonor[donor_id]) {
    //               ediItemsByDonor[donor_id] = [];
    //           }
    //           ediItemsByDonor[donor_id].push(item);
    //         }
    //       }
    //     }
    //   }
    //   return [advtItmes , selfOrdItems , virItems, ediItems, Object.values(ediItemsByDonor)]/// the last element is the array which includes arrays with objects of items according to donor_id eg: [[{},{}], [{},{}], [{},{}]]
    // }

    function removeDuplicates(arr) {
      return [...new Set(arr)];
  }

    const taxValue = await getSalesTax(totalTaxablePrice(cartItems))
    if(taxValue === false) {
      navigate(-1);
      return
    }
    setPrice(totalprice(cartItems)[0]);
    setadvatixItemPrice(totalprice(cartItems)[1]);
    setSelfOrderItemPrice(totalprice(cartItems)[2]);
    setvirtualOrderItemPrice(totalprice(cartItems)[3])
    setEdiOrderItemPrice(totalprice(cartItems)[4])
    setDiscountPrice(totalprice(cartItems)[5])
    setTotalPriceWithoutDiscount(totalprice(cartItems)[6])
    // getShippingRate(cartItems).then((res) => {
    //   setShippingRate(res);
    // });
    cardDetails()
    setAdvatixItems(separateItems(cartItems)[0])
    setselfOrderItems(separateItems(cartItems)[1])
    setvirtualItems(separateItems(cartItems)[2])
    setEdiOrderItems(separateItems(cartItems)[3])
    setEdiOrderItemsByDonor(separateItems(cartItems)[4])
  // to get unique event ID to pass in state to buynow checkOut page
  if(removeDuplicates(getEventId(cartItems)).length > 1){
    setNumberOfevent(2)
  }else{
    setNumberOfevent(1);
    setUniqueEventId(removeDuplicates(getEventId(cartItems))[0])
  }
  if(separateItems(cartItems)[0]?.length > 0) {
    getShippingRate(separateItems(cartItems)[0]).then((res) => {
      setadvatixShippingPrice(res);
    });
  }
  if(separateItems(cartItems)[1]?.length > 0) {
    getShippingRate(separateItems(cartItems)[1]).then((res) => {
      setselfShippingPrice(res);
    });
  }
  // getShippingRate(separateItems(cartItems)[3]).then((res) => setEdiShippingPrice(res))
  if(separateItems(cartItems)[4]?.length) {
    for(let itemsByDonor of separateItems(cartItems)[4]) {
      getShippingRate(itemsByDonor).then((res) => {
        setEdiShippingPrice((prev) => prev + res)
      })
    }
  }
  const advItems = separateItems(cartItems)[0]
  if(advItems?.length) {
    const advTaxValue = await getAdvatixSaleTax(totalTaxablePrice(advItems));
    if(!advTaxValue) {
      navigate(-1)
      return;
    }
  }
  const selfItems = separateItems(cartItems)[1]
  if(selfItems?.length) {
    const selfTax = await getSelfOrderSaleTax(totalTaxablePrice(selfItems));
    if(!selfTax) {
      navigate(-1)
      return;
    }
  }
  const virItems = separateItems(cartItems)[2]
  if(virItems?.length) {
    const virTax = await getVirtualSaleTax(totalTaxablePrice(virItems));
    if(!virTax) {
      navigate(-1)
      return;
    }
  }
  const ediItems = separateItems(cartItems)[3]
  if(ediItems?.length) {
    const ediTax = await getEdiSaleTax(totalTaxablePrice(ediItems));
    if(!ediTax) {
      navigate(-1)
      return;
    }
  }
  })()
  }, [cartItems]);

  useEffect(() => {
    if (cartItems) {
      const separatedItems = separateItems(cartItems); // Get all item categories
      const nonEmptyCategories = separatedItems.filter((items) => items?.length > 0);
    
      // If only one category has items and it's `virItems`
      if (nonEmptyCategories.length === 1 && separatedItems[2]?.length > 0) {
        setShippingRate(0); // Free shipping for virtual-only orders
      } else {
        setShippingRate(9.99); // Standard shipping rate
      }
    }

    // setShippingRate(+advatixShippingRate + +selfShippingRate + +ediShippingRate)
  },[cartItems,advatixShippingRate, selfShippingRate, ediShippingRate])

  const calcFinalPrice = (price, shipping_price, tax) => {
    // console.log("this is price",price, shipping_price, tax)
    let _price = price ? +price : 0;
    let _shipping_price = shipping_price ? +shipping_price : 0;
    let _tax = tax && tax.amount ? +tax.amount : 0;
    let total = _price + _shipping_price + _tax;
    const proccessingFees = Number((_price * 0.03).toFixed(2));
    total += proccessingFees

    return { totalAmount: total, proccessingFees };
  };

  const calcCharityPercentage = (products) => {
    let charityAmount = 0;
    for(let prod of products?.flat(Infinity)) {
      const causeSharePercentage = (Number(prod?.auction_item?.donor?.revenue_splits[0]?.cause_rev_share_percent ) / 100) || 0.8
        charityAmount += Number(getDisplayPrice(prod?.auction_item)) * causeSharePercentage * (Number(prod?.quantity) || 1)
    }
    return charityAmount
  }


  const handleRemove = async (id) => {
    await userServices.removeFromCart(id)
  };


  const handleUpdatePayment = () => {
    navigate('/payment', {
      state: {
        update: true
      }
    })
  }

  const handleEditShipping = () => {
    navigate('/shipping-info', {
      state: { edit: true,type:'cart' },
    });
  }


  const handleCheckout = async (e) => {

    e.preventDefault();
    setIsOrderProcessing(true);
    try {
      // deduct amount
      const { totalAmount } = calcFinalPrice(price, shippingRate, tax)
        // create order using Advatix
      let totalOrderAmount = totalAmount
        try {
          const orderIds={};
          const confirmOrder={};
          let successItemsArray = []
          if(advatixItems.length > 0){
            let {totalAmount,proccessingFees} = calcFinalPrice( advatixItemPrice,advatixShippingRate, advatixSaleTax )
            let items=[];
            for(let i=0; i < advatixItems.length; i++){
              let price=null;
              if(advatixItems[i]?.auction_item?.store_item?.variant){
                price = Number(getDisplayVariantPrice(advatixItems[i]?.auction_item?.store_item?.variant, advatixItems[i]?.auction_item)) - Number(getVariantDiscount(advatixItems[i]?.auction_item?.store_item?.variant, advatixItems[i]?.auction_item))
              }else{
                price= getDisplayPrice(advatixItems[i]?.auction_item) - getDiscount(advatixItems[i]?.auction_item)
              }
              items.push({
                price:price,
                quantity: advatixItems[i].quantity,
                product_sku_id: advatixItems[i].item_sku,
                auction_item_id: advatixItems[i].auction_item_id,
                auction_id:advatixItems[i]?.auction_id,
                cause_id:advatixItems[i].cause_id
              })
            }
            let orderPayload = {
              user_id: authCtx.userId,
              order_amount: +totalAmount.toFixed(2),
              subtotal: advatixItemPrice,
              tax: +advatixSaleTax.amount,
              shipping_rate: +advatixShippingRate,
              proccessingFees: +proccessingFees.toFixed(2),
              items: items
            };
          const advatixOrder = await orderServices.createAdvatixOrder(orderPayload);
          logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): create Advatix Order Response', {advatixOrder:advatixOrder});
          if(advatixOrder?.responseStatus && advatixOrder?.responseStatusCode === 200) {
            confirmOrder.advatixOrder=orderPayload.items;
            confirmOrder.advatix_amount= +totalAmount;
            orderIds.advatixOrderId=advatixOrder.BG_response;
            successItemsArray.push(advatixItems)
           for(let i=0; i<advatixItems.length; i++){
            //update quantity
            let qtyUpdatePayload = {
              sku: advatixItems[i].item_sku,
              quantity: +advatixItems[i].quantity,
              isStoreItem: true,
              isVirtual: advatixItems[i].auction_item?.is_virtual ? true : false,
            };
            await orderServices.updateStoreItemQuantity(
              cartItems[i].auction_item_id,
              qtyUpdatePayload
            );
           }
          }
          else {
            totalOrderAmount = totalOrderAmount - totalAmount;
          }
          }
          if(selfOrderItems.length > 0){
            let { totalAmount, proccessingFees } = calcFinalPrice(selfOrderItemPrice,selfShippingRate,selfItemSaleTax)
            let items=[];
            for(let i=0; i < selfOrderItems.length; i++){
              let price=null;
              if(selfOrderItems[i]?.auction_item?.store_item?.variant){
                price = Number(getDisplayVariantPrice(selfOrderItems[i]?.auction_item?.store_item?.variant, selfOrderItems[i]?.auction_item)) - Number(getVariantDiscount(selfOrderItems[i]?.auction_item?.store_item?.variant, selfOrderItems[i]?.auction_item))
              }else{
                price = getDisplayPrice(selfOrderItems[i]?.auction_item) - getDiscount(selfOrderItems[i]?.auction_item)
              }
              items.push({
                price:price,
                quantity: selfOrderItems[i].quantity,
                product_sku_id: selfOrderItems[i].item_sku,
                auction_item_id: selfOrderItems[i].auction_item_id,
                auction_id:selfOrderItems[i]?.auction_id,
                cause_id:selfOrderItems[i].cause_id
              })
            }
            let orderPayload = {
              user_id: authCtx.userId,
              order_amount: +totalAmount.toFixed(2),
              subtotal: selfOrderItemPrice,
              tax: +selfItemSaleTax.amount,
              shipping_rate: +selfShippingRate,
              proccessingFees: +proccessingFees.toFixed(2),
              items: items
            };
        const selfOrder = await orderServices.createSelfOrder(orderPayload);
        logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): create Self Order Response', {selfOrder:selfOrder});
        successItemsArray.push(selfOrderItems)
        confirmOrder.selfOrder=orderPayload.items;
        confirmOrder.self_order_amount= +totalAmount;
        orderIds.selfOrderId=selfOrder.BG_response;
           for(let i=0; i<selfOrderItems.length; i++){
            //update quantity
            let qtyUpdatePayload = {
              sku: selfOrderItems[i].item_sku,
              quantity: +selfOrderItems[i].quantity,
              isStoreItem: true,
              isVirtual: selfOrderItems[i].auction_item?.is_virtual ? true : false,
            };
            await orderServices.updateStoreItemQuantity(
              selfOrderItems[i].auction_item_id,
              qtyUpdatePayload
            );
           }
          
          }

          // if(ediOrderItems.length > 0) {
          //   let {totalAmount,proccessingFees} = calcFinalPrice(ediOrderItemPrice, ediShippingRate, ediSaleTax)
          //   let items=[];
          //   for(let i=0; i<ediOrderItems.length; i++) {
          //     let price = null;
          //     if(ediOrderItems[i]?.auction_item?.store_item?.variant) {
          //       price= +ediOrderItems[i]?.auction_item?.store_item?.variant.price
          //     }
          //     else {
          //       price= +ediOrderItems[i]?.auction_item?.store_item?.price
          //     }
          //     items.push({
          //       price: price,
          //       quantity: ediOrderItems[i].quantity,
          //       product_sku_id: ediOrderItems[i].item_sku,
          //       auction_item_id: ediOrderItems[i].auction_item_id,
          //       auction_id:ediOrderItems[i]?.auction_id,
          //       cause_id:ediOrderItems[i].cause_id,
          //       item_name: ediOrderItems[i].auction_item?.name
          //     })
          //   }
          //   let orderPayload = {
          //     user_id: authCtx.userId,
          //     order_amount: +totalAmount.toFixed(2),
          //     subtotal: ediOrderItemPrice,
          //     tax: +ediSaleTax.amount,
          //     shipping_rate: +ediShippingRate,
          //     proccessingFees: +proccessingFees.toFixed(2),
          //     items: items
          //   };
            
          //   const ediOrder = await orderServices.createEdiOrder(orderPayload);
          //   if(ediOrder?.status === 200 && (ediOrder?.data?.path && ediOrder?.data?.url))  {
          //     confirmOrder.ediOrder = orderPayload?.items;
          //     confirmOrder.edi_order_amount = +totalAmount;
          //     orderIds.ediOrderId = ediOrder?.BG_response;
          //     orderIds.ediOrderId.generatedFilePath = ediOrder?.data?.path;
          //     successItemsArray.push(ediOrderItems);
              
          //     for(let i=0; i<ediOrderItems?.length; i++) {
          //       // update quantity
          //       let qtyUpdatePayload = {
          //         sku: ediOrderItems[i].item_sku,
          //         quantity: ediOrderItems[i].quantity,
          //         isStoreItem: true,
          //         isVirtual: ediOrderItems[i].auction_item?.is_virtual ? true : false,
          //       }
          //       await orderServices.updateStoreItemQuantity(
          //         cartItems[i].auction_item_id,
          //         qtyUpdatePayload
          //       );
          //     }

          //   }
          //   else {
          //     totalOrderAmount = totalOrderAmount - totalAmount;
          //   }
          // }

          if(ediOrderItems.length > 0) {
            confirmOrder.ediOrder = [];
            orderIds.ediOrderId = []
            // let ediOrder = {}
            for(let i=0; i<ediOrderItemsByDonor.length; i++) {
            // let {totalAmount,proccessingFees} = calcFinalPrice(ediOrderItemPrice, ediShippingRate, ediSaleTax)
            const itemsByDonor = ediOrderItemsByDonor[i]// here this will be again an array
            const ediItemsByDonorPrice = totalprice(itemsByDonor)[4]
            const ediItemsByDonorShippingRate = await getShippingRate(separateItems(itemsByDonor)[3])
            const ediItemsByDonorSalesTax = await getEdiSaleTax(totalTaxablePrice(separateItems(itemsByDonor)[3]));
            // console.log(ediItemsByDonorPrice, ediItemsByDonorShippingRate, ediItemsByDonorSalesTax, "ediItemsByDonorPrice, ediItemsByDonorShippingRate, ediItemsByDonorSalesTax")
            let {totalAmount,proccessingFees} = calcFinalPrice(ediItemsByDonorPrice, ediItemsByDonorShippingRate, ediItemsByDonorSalesTax)
            let items=[];
            for(let i=0; i<itemsByDonor.length; i++) {
              let price = null;
              if(itemsByDonor[i]?.auction_item?.store_item?.variant) {
                price = Number(getDisplayVariantPrice(itemsByDonor[i]?.auction_item?.store_item?.variant, itemsByDonor[i]?.auction_item)) - Number(getVariantDiscount(itemsByDonor[i]?.auction_item?.store_item?.variant, itemsByDonor[i]?.auction_item))
              }
              else {
                price = getDisplayPrice(itemsByDonor[i]?.auction_item) - getDiscount(itemsByDonor[i]?.auction_item)
              }

              function getVendorSkuId(item) {
                if(item?.auction_item?.store_item?.variant && item?.auction_item?.store_item?.variant !== null) {
                  return item?.auction_item?.store_item?.variant?.vendor_sku_id
                }
                else {
                  return item?.auction_item?.store_item?.vendor_sku_id
                }
              }
              function getUPC (item) {
                if(item?.auction_item?.store_item?.variant && item?.auction_item?.store_item?.variant !== null) {
                  return item?.auction_item?.store_item?.variant?.upc
                }
                else {
                  return item?.auction_item?.store_item?.upc
                }
              }
              items.push({
                price: price,
                quantity: itemsByDonor[i].quantity,
                product_sku_id: itemsByDonor[i].item_sku,
                auction_item_id: itemsByDonor[i].auction_item_id,
                auction_id:itemsByDonor[i]?.auction_id,
                cause_id:itemsByDonor[i].cause_id,
                item_name: itemsByDonor[i].auction_item?.name,
                vendor_sku_id: getVendorSkuId(itemsByDonor[i]),
                upc: getUPC(itemsByDonor[i])
              })
            }
            let orderPayload = {
              user_id: authCtx.userId,
              order_amount: +totalAmount.toFixed(2),
              subtotal: ediItemsByDonorPrice,
              tax: +ediItemsByDonorSalesTax.amount,
              shipping_rate: +ediItemsByDonorShippingRate,
              proccessingFees: +proccessingFees.toFixed(2),
              items: items
            };
            const ediOrder = await orderServices.createEdiOrder(orderPayload);
            logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): create Edi Order Response', {ediOrder:ediOrder});
            if(ediOrder?.status === 200 && (ediOrder?.data?.path && ediOrder?.data?.url))  {
              // confirmOrder.ediOrder = [];
              // confirmOrder.ediOrder.push(orderPayload?.items);
              // confirmOrder.edi_order_amount = +totalAmount;
              ediOrder.BG_response.generatedFilePath = ediOrder?.data?.path
              ediOrder.BG_response.items = orderPayload?.items
              confirmOrder.ediOrder.push(ediOrder?.BG_response)
              orderIds.ediOrderId.push(ediOrder?.BG_response)
              // confirmOrder.ediOrder = orderPayload?.items;
              // orderIds.ediOrderId = ediOrder?.BG_response;
              // orderIds.ediOrderId.generatedFilePath = ediOrder?.data?.path;
              successItemsArray.push(itemsByDonor);
              
              for(let i=0; i<itemsByDonor?.length; i++) {
                // update quantity
                let qtyUpdatePayload = {
                  sku: itemsByDonor[i].item_sku,
                  quantity: itemsByDonor[i].quantity,
                  isStoreItem: true,
                  isVirtual: itemsByDonor[i].auction_item?.is_virtual ? true : false,
                }
                // console.log(cartItems[i], "cartItems[i]")
                await orderServices.updateStoreItemQuantity(
                  itemsByDonor[i].auction_item_id,
                  qtyUpdatePayload
                );
              }

            }
            else {
              totalOrderAmount = totalOrderAmount - totalAmount;
            }
          }
        }
        // setIsOrderProcessing(false);
        // console.log(confirmOrder, successItemsArray, orderIds, 'confirmOrder, successItemsArray, orderIds')
        // return;

          if(virtualItems.length > 0){
            let {totalAmount,proccessingFees} = calcFinalPrice(virtualOrderItemPrice,0,virtualSaleTax)
            let items=[];
            for(let i=0; i < virtualItems.length; i++){
              let price=null;
              if(virtualItems[i]?.auction_item?.store_item?.variant){
                price = Number(getDisplayVariantPrice(virtualItems[i]?.auction_item?.store_item?.variant, virtualItems[i]?.auction_item)) - Number(getVariantDiscount(virtualItems[i]?.auction_item?.store_item?.variant, virtualItems[i]?.auction_item))
              }else{
                price = getDisplayPrice(virtualItems[i]?.auction_item) - getDiscount(virtualItems[i]?.auction_item)
              }
              items.push({
                price:price,
                quantity: virtualItems[i].quantity,
                product_sku_id: virtualItems[i].item_sku,
                auction_item_id: virtualItems[i].auction_item_id,
                auction_id:virtualItems[i]?.auction_id,
                cause_id:virtualItems[i].cause_id
              })
            }
            let orderPayload = {
              user_id: authCtx.userId,
              order_amount: +totalAmount.toFixed(2),
              subtotal: virtualOrderItemPrice,
              tax: +virtualSaleTax.amount,
              shipping_rate: 0,
              proccessingFees: +proccessingFees.toFixed(2),
              items: items
            };
          const virtualOrder= await orderServices.createVirtualOrder(orderPayload);
          logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): create Virtual Order Response', {virtualOrder:virtualOrder});
          successItemsArray.push(virtualItems)
          confirmOrder.virtualOrder=orderPayload.items;
          confirmOrder.virtual_amount= +totalAmount;
          orderIds.virtualOrderId=virtualOrder.BG_response;

           for(let i=0; i<virtualItems.length; i++){
            //update quantity
            let qtyUpdatePayload = {
              sku: virtualItems[i].item_sku,
              quantity: +virtualItems[i].quantity,
              isStoreItem: true,
              isVirtual: virtualItems[i].auction_item?.is_virtual ? true : false,
            };
            await orderServices.updateStoreItemQuantity(
              virtualItems[i].auction_item_id,
              qtyUpdatePayload
            );
           }
           
          }
          ///payment
          let payload = {
            amount: Number(totalOrderAmount.toFixed(2)),
          };
          try {
            if(payload?.amount <= 0) {
              alert(constants.APP_COPY_ORDER_FAILED);
              logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): amount 0', payload);
              setIsOrderProcessing(false);
              return navigate(-1)
            }
            const response = await createPayment(payload);
            if(response.success){
              await orderServices.confirmOrder(orderIds,confirmOrder);

              //meta pixel tracking event
              let content_ids=[]
              let contents=[]
             cartItems.map(item=>{
              content_ids.push(item.auction_item_id)
              contents.push({
                name:item.auction_item.name,
                id:item.auction_item_id,
                sku:item.item_sku,
                quantity:item.quantity
              })
                 return null 
                })
              Purchase({content_ids,contents,num_items:cartItems.length,value:totalOrderAmount.toFixed(2),currency:"USD"})
              setIsOrderProcessing(false);
              dispatch(resetCart())
              navigate('/checkout',{
                state:{
                  noOfEvent:noOfEvent,
                  auctionId: uniqueEventId,
                  charityAmount: Number(calcCharityPercentage(successItemsArray)).toFixed(2)
                }
              });
            }
          } catch(error) {
            logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): updating payment failure', {orderIds: orderIds});
            await orderServices.updatePaymentFailure(orderIds);
            // restore advatix items
            for(let i=0; i<advatixItems.length; i++){
              //update quantity
              let qtyUpdatePayload = {
                sku: advatixItems[i].item_sku,
                quantity: +advatixItems[i].quantity,
                isStoreItem: true,
                isVirtual: advatixItems[i].auction_item?.is_virtual ? true : false,
                incrementStock:true
              };
              await orderServices.updateStoreItemQuantity(
                cartItems[i].auction_item_id,
                qtyUpdatePayload
              );
             }

             ///restore self items
             for(let i=0; i<selfOrderItems.length; i++){
              //update quantity
              let qtyUpdatePayload = {
                sku: selfOrderItems[i].item_sku,
                quantity: +selfOrderItems[i].quantity,
                isStoreItem: true,
                isVirtual: selfOrderItems[i].auction_item?.is_virtual ? true : false,
                incrementStock:true
              };
              await orderServices.updateStoreItemQuantity(
                selfOrderItems[i].auction_item_id,
                qtyUpdatePayload
              );
             }

            //  restore ediItems
            if(ediOrderItems.length) {
              for(let i=0; i<ediOrderItems.length; i++) {
                //update quantity
              let qtyUpdatePayload = {
                sku: ediOrderItems[i].item_sku,
                quantity: +ediOrderItems[i].quantity,
                isStoreItem: true,
                isVirtual: ediOrderItems[i].auction_item?.is_virtual ? true : false,
                incrementStock:true
              };
              await orderServices.updateStoreItemQuantity(
                ediOrderItems[i].auction_item_id,
                qtyUpdatePayload
              );
              }
            }

             ///restore virtual items
             for(let i=0; i<virtualItems.length; i++){
              //update quantity
              let qtyUpdatePayload = {
                sku: virtualItems[i].item_sku,
                quantity: +virtualItems[i].quantity,
                isStoreItem: true,
                isVirtual: virtualItems[i].auction_item?.is_virtual ? true : false,
                incrementStock:true
              };
              await orderServices.updateStoreItemQuantity(
                virtualItems[i].auction_item_id,
                qtyUpdatePayload
              );
             }
             alert(constants.APP_COPY_PAYMENT_FAILED);
             setIsOrderProcessing(false);
          }

        } catch (error) {
          logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): catch1', error);
          // console.log('Error at handleCheckout() catch 1 ---', error)
          alert(error?.response?.data?.responseMsg || error?.response?.data?.message || constants.APP_COPY_ORDER_FAILED);
          setIsOrderProcessing(false);
          navigate(-1)
        }
      
    } catch (error) {
      logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): catch2', error);
      // console.log('Error at handleCheckout() catch 2 ---', error)
      alert(error?.response?.data?.message || constants.APP_COPY_ORDER_FAILED);
      setIsOrderProcessing(false);
      navigate(-1)
    }
  };

  return (
    <div className="cart-outer-container" >
      {!isOrderProcessing && <>
        {/* <HeaderV2 logoType='black2' primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} containerStyle={{position:"fixed"}} isSubHeader subHeaderTitle="Checkout"/> */}
        <HeaderV3 primaryBackgroundColor={primaryBackgroundColor} isSubHeader subHeaderTitle="Checkout" position="absolute"/>
      </>}
    

      {isOrderProcessing && (
        <LoadingScreen loadingText="Processing your order ..."/>
      )}
      <div style={{ padding:isMobile ? "55px 5vw" : "", minHeight: "48vh", backgroundColor:primaryBackgroundColor ,paddingTop:isMobile?"170px":"200px" }} className={` ${isMobile ? "bag-container" : "margin_class"}`}>
        {(cartItems.length === 0) ? 
          <div className="no-items">Your Cart Is Empty</div>
         : (
          <div className='bag-cards-container'>

            {cartItems.map((item, i) => {
              if(item.auction_item.store_item?.variant) {
                if (item.auction_item.store_item?.variant?.quantity_in_stock < item.quantity){
                 return null
                } 
               }else{
                 if(item.auction_item.store_item?.quantity_in_stock < item.quantity){
                   return null
                 }
               }
            return (
              <React.Fragment  key={item.id}>
                
                  <NewCardLayout imageContainerStyle={{width:!isMobile && '150px', height:!isMobile && '150px'}} image={item.auction_item.assets.find(img=>(img.context_type==='thumbnail'||img.context_type==='portrait'))?.name} neededLine={true}>
                  <div className="cart-card-content" style={{gap:'5px'}}>
                    <h1>{item.auction_item?.name}</h1>
                    <div style={{marginTop: "-10px", fontSize: "12px", display: "flex", gap: "10px", marginBottom: "10px"}}>
                       {item.auction_item.store_item.variant &&  item.auction_item.store_item.option ? (
                         Object.keys(item.auction_item.store_item.option).map((key, index) => (
                           <span key={index} style={{color:primaryColor}}>{key}: {item.auction_item.store_item.option[key]}</span>
                         ))
                       ) : null}
                     </div>
                     <div style={{marginTop: "-10px", marginBottom: "5px",fontSize: "12px",}}>
                       {
                           (item.auction_item.store_item?.variant) ?  
                           
                             (item.auction_item.store_item?.variant?.quantity_in_stock < item.quantity ) ? <span style={{color:mobileStyle.color[0]}}>Sold Out</span> : ""
                           : (item.auction_item.store_item?.quantity_in_stock < item.quantity ) ? <span style={{color:mobileStyle.color[0]}}>Sold Out</span> : ""
                       }
                     </div>
                    <div className="cart-card-qty_price">
                      <div className="cart-card-remove_price">
                        <h5 style={{color:primaryColor,fontWeight:"600", textDecoration: currentDiscountFromAuction[0] && (Number(getVariantDiscount(item.auction_item?.store_item?.variant, item?.auction_item)) !== 0 || Number(getDiscount(item.auction_item)) !== 0) ? 'line-through' : 'none'}} >
                        {item.auction_item.store_item.variant
                          ? ` $${(item?.quantity * (Number(getDisplayVariantPrice(item.auction_item.store_item.variant, item.auction_item))).toFixed(2)).toFixed(2)}`
                          : ` $${(item?.quantity * (+getDisplayPrice(item.auction_item))).toFixed(2)}`}
                        </h5>
                      </div>
                      {(Number(getVariantDiscount(item.auction_item?.store_item?.variant, item?.auction_item)) !== 0 ||
                        Number(getDiscount(item.auction_item)) !== 0) &&  
                        currentDiscountFromAuction[0] && <h5 style={{color: "var(--red-color)",fontWeight:"600"}}>
                            ${
                               item.auction_item.store_item.variant
                                ? `${(item.quantity * (Number(getDisplayVariantPrice(item.auction_item?.store_item?.variant, item?.auction_item)) - Number(getVariantDiscount(item.auction_item?.store_item?.variant, item?.auction_item)))).toFixed(2)}`
                              : `${(item.quantity * (Number(getDisplayPrice(item.auction_item)) - Number(getDiscount(item.auction_item)))).toFixed(2)}`
                            } Flash Sale Price
                      </h5>}
                    </div>
                  </div>
                  <div style={{marginTop:'5px'}} className="mobile_quantity_wrapper">
                    <span className="quantity_text">Qty</span>
                    <span className="quantity_text">{item.quantity}</span>
                  </div>
                </NewCardLayout>
                </React.Fragment>
            );
          })}
          </div>
        )}
      {
        (cartItems.length === 0 || price <= 0) ?
        null :
         <>
        <div>
        <div className="bag-order-summary" style={{width:isMobile?"":"65%"}}>
        <h1 style={{marginBottom:'18px'}}>Order Summary</h1>
        <div className="bag-summary-grid">
          <div style={{display:'flex', flexDirection:'column', marginBottom:'35px'}}>
          {currentDiscountFromAuction[0] && <div style={{fontSize: "1.2rem", fontWeight: '600'}}>
              <div className="cart_subtotal_heading">Items</div>
              <div className="bag-card-price">${Number(totalPriceWithoutDiscount).toFixed(2)}</div>
            </div>}
            {currentDiscountFromAuction[0] && 
            <div style={{fontSize: "1.2rem", fontWeight: '600', color:'#FC3300'}}>
              <div className="cart_subtotal_heading">{showDiscountPercentage ? `${currentDiscountFromAuction[0]}% Flash Sale Discount` : 'Total Flash Sale Extra Savings'}</div>
              <div className="bag-card-price">${Number(discountPrice).toFixed(2)}</div>
            </div>}
          <div>
            <div className="cart_subtotal_heading">Subtotal</div>
            <div className="bag-card-price">${Number(price).toFixed(2)}</div>
          </div>
          { (Number(shippingRate).toFixed(2) > 0 ) &&
          <div>
            <div className="cart_subtotal_heading">Shipping 
               <Tooltip title="Based on 5-8 Day USPS shipping rates" placement="top-start" >
                <span onTouchStart={()=>{setTooltip1(!tooltip1);setTooltip2(false)}}> <InfoOutlinedIcon sx={{fontSize:'18px', color: primaryColor}} /></span>
                </Tooltip>
                {(tooltip1) && <div className="tooltip-container" style={{top:"-25px"}}>
                 <p>Based on 5-8 Day USPS shipping rates.</p>
                </div>
                }
            </div>
            <div className="bag-card-price">
              ${Number(shippingRate).toFixed(2)}
            </div>
          </div>
          }
           { (tax?.amount > 0) && 
           <div>
              <div className="cart_subtotal_heading">Tax <span>({`${tax?.amount === 0 ? 0 : tax?.percentage}%`})</span></div>
              <div className='bag-card-price'>${tax.amount}</div>
            </div>
            }
            <div>
              <div className="cart_subtotal_heading">Processing Fees <span>(3%) </span>
                <Tooltip title="Covers Stripe credit card processing fees so that more of your dollars can go directly to nonprofits on our platform." placement="top-start" > 
                <span onTouchStart={()=>{setTooltip1(false);setTooltip2(!tooltip2);}}  >  <InfoOutlinedIcon sx={{fontSize:'18px', color: primaryColor}} /> </span> 
                </Tooltip>
                {(tooltip2) && <div className="tooltip-container">
                 <p>Covers Stripe credit card processing fees so that more of your dollars can go directly to nonprofits on our platform.</p>
                </div>
                }
              </div>

              <div className='bag-card-price'>${Number(calcFinalPrice(price, shippingRate, tax).proccessingFees).toFixed(2)}</div>
            </div>
          </div>
           <hr style={{ color:primaryColor, margin:'0', width:'100%'}} />
            <div style={{marginBlock:"18px", paddingBottom:'0 !important'}} className='bag-summary-grid-total'>
              <div className="cart_subtotal_heading">Total</div>
              <div style={{gridTemplateColumns:'1fr'}} className='bag-card-price'>
                ${Number(calcFinalPrice(price, shippingRate, tax).totalAmount).toFixed(2)}
              </div>
            </div>

            {/* here commented one may need in the future */}
            {/* <hr style={{color:primaryColor, margin:'0', width:'100%', border:'1px solid'}} />
            <div style={{marginBlock:"25px", paddingTop:'0 !important'}} className='bag-summary-grid-total remove-padding-class'>
              <div style={{fontWeight:600, fontSize:"20px", color:redColor}} className="cart_subtotal_heading">Amount Going To Charity</div>
              <div style={{gridTemplateColumns:'1fr', fontWeight:800, color:redColor}} className='bag-card-price'>
                ${Number(calcCharityPercentage(price)).toFixed(2)}
              </div>
            </div>
            <hr style={{ color:primaryColor, margin:'0', width:'100%'}} /> */}
        </div>

        <div className="shipping_payment_container">
        {cardDetails && (
          <div>
            <div className='order_headings' style={{ marginTop: "42px", marginBottom:'17px' }}>Payment Method</div>
            <div  className='bag_shipping_sec' style={{backgroundColor:isMobile?"":"white"}}>
              <div className='bag_shipping_content' style={{ fontSize: "1rem" }}>
                <div>{`${cardDetails.name}`}</div>
                <div>{`**** **** **** ${cardDetails.last4}`}</div>
                <div>{cardDetails.brand}</div>
              </div>
              <div
                onClick={handleUpdatePayment}
                className='bag_shipping_edit'>
                <img src={EditSVG} alt="edit" />
              </div>
            </div>
          </div>
        )}

        {shipingData && (
          <div>
            <div className='order_headings' style={{ marginTop: "42px", marginBottom:'17px' }}>Ship To This Address</div>
            <div className='bag_shipping_sec' style={{backgroundColor:isMobile?"":"white"}}>
              <div className='bag_shipping_content'>
                <div>{`${shipingData?.to_firstname} ${shipingData?.to_lastname}`}</div>
                <div>{`${shipingData?.address1}`}</div>
                <div>{shipingData?.city}, {shipingData?.state}, {shipingData?.zip}</div>
              </div>
              <div
                onClick={handleEditShipping}
                className='bag_shipping_edit'>
                <img src={EditSVG} alt="edit" />
              </div>
            </div>
          </div>
        )}
        </div>
      </div>


        <NewButton onClick={handleCheckout} text={'PLACE ORDER'} backgroundColor={redColor} color={primaryBackgroundColor} 
           style={{width:isMobile?'100%':"36%",  fontSize:'16px', lineHeight:"16px", paddingBlock:'9px', marginTop: "42px"}}
        />
 
        {/* <div style={{ textAlign: 'center' }}>
          <button onClick={() => navigate("/")} className='buy-checkout-btn-outline'>Keep shopping</button>
        </div> */}
        </div>

         <NeedHelp primaryColor={primaryColor}/>


         </>
      }
         

      </div>
      <div style={{width:'100%',backgroundColor:'#F0E8E3'}}>
      {/* <MobileSubFooter needHelp style={{padding:'6vw 5vw 2vw 5vw'}} primaryBackgroundColor="#F0E8E3" primaryColor='#30313A'/> */}
      <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

      </div>
      <DialogMui isOpen={modal.isOpen} message={modal.message} ButtonText="Enter Details" handleClick={handleUpdatePayment} />
      <DialogMui isOpen={modal2.isOpen} message={modal2.message} ButtonText="Enter Details" handleClick={handleEditShipping} />
    </div>
  );
};

export default CartCheckout;